import React, { useEffect, useState } from 'react';
import { Portal, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, 
    Text, Tooltip, Box, Wrap, Badge, useToast } from '@chakra-ui/react';
import { FaTags } from 'react-icons/fa';
import { useUser } from 'contexts/UserContext';
import { useAuth } from 'contexts/AuthContext';
import { getEnvVariable } from 'utils/env';
import { tagUpload } from 'utils/api';

export function TagPopover({
    hash = "",
    onChange = v=>{console.warn("no onChange handler for TagPopover", v)}
}) {
    const { token } = useAuth();
    const toast = useToast();
    const [selectedTags, setSelectedTags] = useState([])
    const [isTagging, setIsTagging] = useState(false)
    const { refresh, datasets, fetching: datasetsFetching } = useUser();

    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

    return <Popover onOpen={e => {
        // Fetch tags of this item
        console.log(`${REACT_APP_api_url}/v3/getimagehash/${hash}`)
        fetch(`${REACT_APP_api_url}/v3/getimagehash/${hash}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => response.json())
            .then(data => {
                data?.tags?.length && setSelectedTags(data.tags)
            })
            .catch(e => {
                console.error(e);
                setSelectedTags([])
            })

    }}>
        <PopoverTrigger><span>
            <Tooltip hasArrow openDelay={250} label="Tag to Dataset...">
                <IconButton
                    isRound
                    isLoading={isTagging}
                    icon={<FaTags />}
                    colorScheme="green"
                    variant={'solid'}
                />
            </Tooltip>
        </span></PopoverTrigger>
        <Portal>
            <PopoverContent>
                <PopoverHeader fontWeight="semibold">Tag to Dataset</PopoverHeader>
                <PopoverArrow />
                <PopoverBody maxHeight={'15rem'} overflowY={'auto'}>
                    <Box>
                        <Wrap>
                            {datasets.map(d => <Badge style={{ cursor: "pointer" }} key={d._id} colorScheme={selectedTags?.includes(d._id) ? "green" : "grey"} onClick={e => {
                                setIsTagging(true)
                                tagUpload(hash, d._id, token).then((updatedTags) => {
                                    setIsTagging(false)
                                    setSelectedTags([...updatedTags])
                                    // This is not causing a re-render
                                    toast({
                                        title: "Tagged",
                                        description: "Tagged to dataset",
                                        status: "success",
                                        duration: 300,
                                        isClosable: true,
                                    })
                                    refresh()
                                    onChange && onChange(updatedTags)
                                }).catch(e => {
                                    setIsTagging(false)
                                    setSelectedTags([])
                                    console.error(e)
                                })
                            }}>{d.name}</Badge>)}
                        </Wrap>
                    </Box>
                </PopoverBody>
            </PopoverContent>
        </Portal>
    </Popover>
}